export const ISSUE_GET_SUCCESS = "GET_SUCCESS";
export const ISSUE_GET_FAILURE = "GET_FAILURE";
export const ISSUE_ADD_FAILURE = "ADD_FAILURE";
export const ISSUE_ADD_SUCCESS = "ADD_SUCCESS";
export const ISSUE_REMOVE_SUCCESS = "REMOVE_SUCCESS";
export const ISSUE_REMOVE_FAILURE = "REMOVE_FAILURE";
export const ISSUE_ASK_APPROVAL = "ISSUE_ASK_APPROVAL";
export const ISSUE_ASK_APPROVAL_SUCCESS = "ISSUE_ASK_APPROVAL_SUCCESS";
export const ISSUE_ASK_APPROVAL_FAILURE = "ISSUE_ASK_APPROVAL_FAILURE";
export const ISSUE_LOADING = "ISSUE_LOADING";
