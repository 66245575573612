import React, { useState } from "react";

import { useTheme } from "@material-ui/core/styles";
import {
  makeStyles,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DialogBox from "../../Components/Modal/DialogBox";
const useStyles = makeStyles({
  btn: {
    padding: 20,
    backgroundColor: "#333",
    color: "#fff",
  },
  title: {
    backgroundColor: "#345BAE",
    padding: "11px 10px",
    color: "#fff",
    borderRadius: 11,
  },
  footer: {
    width: "100%",
    textAlign: "center",
  },
  footerlink: {
    backgroundColor: "#345BAE",
    padding: "11px 10px",
    borderRadius: 11,
    color: "#fff",
  },
  getcol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "100%",
  },
  noUpdates: {
    padding: "1em",
    textAlign: "center",
  },
});

export default function VerticleList(props) {
  // console.log(props.activityDetails);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelImageUrl, setModelImageUrl] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();
  const handleShowDialog = url => {
    if (!isModalOpen) setModelImageUrl(url);
    setIsModalOpen(!isModalOpen ? true : false);
  };
  return (
    <>
      <DialogBox
        handleShowDialog={handleShowDialog}
        isModalOpen={isModalOpen}
        modelImageUrl={modelImageUrl}
      />
      <div className={classes.title}>
        <Typography variant="h6">Latest Updates</Typography>
      </div>
      {props.activityDetails.records ? (
        props.activityDetails.records.slice(0, 3).map((detail, detailIndex) => {
          return (
            <Card
              variant="outlined"
              style={{ margin: "5px", width: "100%" }}
              elevation={3}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={8} md={8}>
                    <Typography variant="p" className={classes.getcol}>
                      Updated by:{detail.data[0].user}
                      {/* {detail.user} */}
                    </Typography>
                    <Typography variant="p" className={classes.getcol}>
                      Update Date :{detail.data[0].date}
                    </Typography>

                    <Typography variant="p" className={classes.getcol}>
                      Activity Name:
                      {detail.data[0].activityName}
                    </Typography>
                    <Typography variant="p" className={classes.getcol}>
                      Update Percentage:
                      {detail.data[0].updatedPercentage}%
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ paddingRight: "10px" }}
                      onClick={() => {
                        if (detail.data[0].full_panaroma_url)
                          handleShowDialog(detail.data[0].full_panaroma_url);
                        else handleShowDialog(detail.data[0].panaroma);
                      }}
                    // startIcon={<Info />
                    >
                      View Panaroma
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })
      ) : (
        <div className={classes.noUpdates}>
          <Typography variant="p">Updates Not Available</Typography>
        </div>
      )}
      {props.activityDetails.records && props.activityDetails.records.length > 0 ?
        <div className={classes.footer}>
          <Link
            style={{ textDecoration: "none" }}
            to={{
              state: {
                activityDetails: props.activityDetails,
              },
              pathname: `/overview/viewupdates`,
            }}
          >
            <Typography variant="p" className={classes.footerlink}>
              View More {">>"}
            </Typography>
          </Link>
        </div> : ''}


    </>
  );
}
