import React, { useState, useEffect } from "react";

import { useTheme } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Grid,
} from "@material-ui/core";

export default function EditItem(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //   useEffect(() => {
  //     setItemName(props.itemName)
  //     setRate(props.rate)
  //     setUnit(props.unit)
  //     console.log(itemName)
  //   }, [props.open])
  return (
    <div>
      <Dialog
        open={props.open}
        // fullScreen={fullScreen}
      >
        {/* <DialogTitle>Edit </DialogTitle> */}
        <form onSubmit={props.isAdditem ? props.handleAddItem : props.handleItemsubmit}>
          <DialogContent>
            <DialogContentText>Provide new Details</DialogContentText>

            <TextField
              margin="dense"
              id="itemName"
              name="item"
              label="Name"
              type="text"
              variant="standard"
              value={props.item}
              onChange={event => {
                props.handleItemChange(event.target.name, event.target.value);
              }}
              fullWidth
              required
            />

            <TextField
              margin="dense"
              id="unit"
              name="unit"
              label="unit"
              type="text"
              variant="standard"
              value={props.unit}
              onChange={event => {
                props.handleItemChange(event.target.name, event.target.value);
              }}
              fullWidth
              required
            />
            <TextField
              margin="dense"
              id="rate"
              name="rate"
              label="rate"
              type="number"
              variant="standard"
              value={props.rate}
              onChange={event => {
                props.handleItemChange(event.target.name, event.target.value);
              }}
              fullWidth
              required
            />
          </DialogContent>
          <DialogActions>
          {!props.isAdditem ? (
            <>
            <Button
              onClick={() => {
                props.handleItemModalOpen();
              }}
              color="secondary"
            >
              Cancel
            </Button>
            
              <Button
                //  onClick={(e) =>{
                //   props.handleItemsubmit(e)
                //  }}
                type="submit"
                color="primary"
              >
                Submit
              </Button>
              </>
            ) : (
              <>
            <Button
              onClick={() => {
                props.handleItemModalCancel();
              }}
              color="secondary"
            >
              Cancel
            </Button>
            
              <Button
                type="submit"
                color="primary"
              >
                Submit
              </Button>
              </>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
