import React ,{useState,useEffect} from "react";

import { useTheme } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Grid,
} from "@material-ui/core";

export default function Editlist(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [itemName,setItemName]=useState("")
  const [rate,setRate]=useState(0)
  const [unit,setUnit]=useState("")
 const handleNamechange=(event) =>{
  setItemName(event.target.value)
  }
  const handleUnitchange=(event) =>{
    setUnit(event.target.value)

    }
    const handleRatechange=(event) =>{
      setRate(event.target.value)
      }
  useEffect(() => {
    console.log("in use effedt",props.itemName)
    setItemName(props.itemName)
    setRate(props.rate)
    setUnit(props.unit)
    console.log(itemName)
  }, [props.open])
  return (
    <div>
     <Dialog open={props.open} 
           // fullScreen={fullScreen}
            >
        {/* <DialogTitle>Edit </DialogTitle> */}
        <form onSubmit={props.handlesubmit}> 
        <DialogContent>
          <DialogContentText>Provide  new Details</DialogContentText>
        
          <TextField
            margin="dense"
            id="itemName"
            name="itemName"
            label="Name"
            type="text"
            variant="standard"
            value={props.itemName}
            onChange={(event) =>{props.handleFieldsChange(event.target.name,event.target.value)}}
            error={props.nameError && "name already exists"}
            helperText={props.nameError && "name already exists"}
          //  onChange={this.props.handleTaskChange}
            fullWidth
            required
          />
          {!props.isContractorRA && (
            <>
               <TextField
            margin="dense"
            id="unit"
            name="unit"
            label="unit"
            type="text"
            variant="standard"
            value={props.unit}
            // error={props.alertSubActivity}
            // helperText={props.alertSubActivityField}
            onChange={(event) =>{props.handleFieldsChange(event.target.name,event.target.value)}}
            fullWidth
            required
          />
           <TextField
            margin="dense"
            id="rate"
            name="rate"
            label="rate"
            type="number"
            variant="standard"
            value={props.rate}
            onChange={(event) =>{props.handleFieldsChange(event.target.name,event.target.value)}}
            fullWidth
            required
          />
            </>
          )}
         
         {props.isContractorRA &&
           <TextField
           margin="dense"
           id="pancard_no"
           name="pancard_no"
           label="Pancard Number"
           type="text"
           variant="standard"
           value={props.pancard_no}
           onChange={(event) =>{props.handleFieldsChange(event.target.name,event.target.value)}}
           fullWidth
           required
         />}
         {props.isContractorRA &&
           <TextField
           margin="dense"
           id="gst_no"
           name="gst_no"
           label="GST number"
           type="text"
           variant="standard"
           value={props.gst_no}
           onChange={(event) =>{props.handleFieldsChange(event.target.name,event.target.value)}}
           fullWidth
           required
         />}
        
        </DialogContent>
        <DialogActions>
          <Button 
          onClick={() =>{props.handleModalOpen()}} 
          color="secondary">
            Cancel
          </Button>
         
          <Button 
         // onClick={props.handlesubmit} 
         type="submit"
          color="primary">
            Submit
          </Button>
        </DialogActions>
        </form>
      </Dialog>
      
    </div>
  );
}
