import React from "react";

import { useTheme } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Grid,
} from "@material-ui/core";

export default function EditCheckList(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <Dialog open={props.open} fullScreen={fullScreen}>
        <DialogTitle>Edit CheckList</DialogTitle>
        <DialogContent>
          <DialogContentText>Edit CheckList</DialogContentText>
          <TextField
            margin="dense"
            id="taskName"
            name="checklistTitleEdit"
            label="Checklist Title"
            type="text"
            variant="standard"
            value={props.checklistTitleEdit}
            // error={props.alertSubActivity}
            // helperText={props.alertSubActivityField}
            onChange={props.handleTaskChange}
            fullWidth
          />
         {props.checklistPointEdit && props.checklistPointEdit.length !==0 &&
         props.checklistPointEdit.map((point,index) =>{
          return (
            <TextField
            margin="dense"
            id="checklistpoint1"
            name={`Checklistpoint${index+1}`}
            label={`Checklist Point ${index+1}`}
            type="text"
            variant="standard"
            value={props.checklistPointEdit[index].description}
            // error={props.alertSubActivity}
            // helperText={props.alertSubActivityField}
            onChange={(e) =>{ props.handleChecklistPointEdit(e,index)}}
            fullWidth
          />
          )
         })
         }
          
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleEditModalOpen} color="secondary">
            Cancel
          </Button>
          {/* <Button onClick={props.submitTask} color="primary"> */}
          <Button onClick={props.editActivity} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
