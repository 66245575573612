import "date-fns";
import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Table,
  TableRow,
  TableCell,

} from "@material-ui/core";
import Style from "../style.module.scss";
import MUIDataTable from "mui-datatables";

const ActivityReport = props => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 760);
 
  let columns =[]
  if(props.DprReportFormat=="Equipment Wise"){
    columns = [
      {
        name: "equipment",
        label: "Equipment Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "number",
        label: "Quantity",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "unit",
        label: "unit",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "start",
        label: "Start Time",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "end",
        label: "End Time",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "totalHours",
        label: "Total",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "trip",
        label: "Trip",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "diesel",
        label: "Diesel",
        options: {
          customHeadRender: ({index, ...column}) => {
            return (
              <TableCell key={index} style={{width:'8px'}}>
                 {column.label}
             </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "equipmentMaterial",
        label: "Material",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "date",
        label: "Date",
        options: {
          filter: true,
          sort: true,
        },
      },
    ]
  }
  else if(props.DprReportFormat=='Material Wise'){
    columns = [
      {
        name: "equipmentMaterial",
        label: "Material",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "equipment",
        label: "Equipment Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "number",
        label: "Quantity",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "unit",
        label: "unit",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "start",
        label: "Start Time",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "end",
        label: "End Time",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "totalHours",
        label: "Total",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "trip",
        label: "Trip",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "diesel",
        label: "Diesel",
        options: {
          customHeadRender: ({index, ...column}) => {
            return (
              <TableCell key={index} style={{width:'8px'}}>
                 {column.label}
             </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "date",
        label: "Date",
        options: {
          filter: true,
          sort: true,
        },
      },
    ]
  }
  else if(props.DprReportFormat=='Date Wise'){
    columns = [
      {
        name: "date",
        label: "Date",
        options: {
          customHeadRender: ({index, ...column}) => {
            return (
              <TableCell key={index} style={{width:'8px'}}>
                 {column.label}
             </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "equipment",
        label: "Equipment Name",
        options: {
          customHeadRender: ({index, ...column}) => {
            return (
              <TableCell key={index} style={{width:'8px'}}>
                 {column.label}
             </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },

      {
        name: "number",
        label: "Quantity",
        options: {
          customHeadRender: ({index, ...column}) => {
            return (
              <TableCell key={index} style={{width:'8px'}}>
                 {column.label}
             </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "unit",
        label: "unit",
        options: {
          customHeadRender: ({index, ...column}) => {
            return (
              <TableCell key={index} style={{width:'8px'}}>
                 {column.label}
             </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "start",
        label: "Start",
        options: {
          customHeadRender: ({index, ...column}) => {
            return (
              <TableCell key={index} style={{width:'8px'}}>
                 {column.label}
             </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "end",
        label: "End",
        options: {
          customHeadRender: ({index, ...column}) => {
            return (
              <TableCell key={index} style={{width:'8px'}}>
                 {column.label}
             </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "totalHours",
        label: "Total",
        options: {
          customHeadRender: ({index, ...column}) => {
            return (
              <TableCell key={index} style={{width:'8px'}}>
                 {column.label}
             </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "trip",
        label: "Trip",
        options: {
          customHeadRender: ({index, ...column}) => {
            return (
              <TableCell key={index} style={{width:'8px'}}>
                 {column.label}
             </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "diesel",
        label: "Diesel",
        options: {
          customHeadRender: ({index, ...column}) => {
            return (
              <TableCell key={index} style={{width:'8px'}}>
                 {column.label}
             </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "equipmentMaterial",
        label: "Material",
        options: {
          customHeadRender: ({index, ...column}) => {
            return (
              <TableCell key={index} style={{width:'8px'}}>
                 {column.label}
             </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
    ]
  }
  
 
  return (
    <>
   
    <div className="container" id="download-section">
      <Grid container className={Style.header___top}>
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ marginLeft: "0.5em", color: "#fff" }}
        >
          Enbuild
        </Typography>
      </Grid>
      <Grid container className={Style.container___info}>
        <Grid item xs={3}>
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginLeft: "0.5em" }}
          >
            Generated By
          </Typography>
          <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
            {global.config.secureStorage.getItem("user_fullname")}
          </Typography>
        </Grid>
        
        <Grid item xs={3}>
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginLeft: "0.5em" }}
          >
            Generated On
          </Typography>
          <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
            {new Date(new Date()).toISOString().split("T")[0]}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginLeft: "0.5em" }}
          >
            Start Date
          </Typography>
          <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
            {startDate}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginLeft: "0.5em" }}
          >
            End Date
          </Typography>
          <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
            {endDate}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={Style.container___info}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
            DPR Report
          </Typography>
          <Typography
            variant="p"
            color="textSecondary"
            style={{ marginLeft: "0.5em" }}
          >
            {/* This Report is from {props.startDate} to {props.endDate} */}
          </Typography>
        </Grid>
      </Grid>
      <hr />

      <div className={Style.table___box}>
      {props.newWbs !== undefined && (
              <MUIDataTable
                title={`Equipments`}
                data={props.newWbs}
                columns={columns}
                options={{
                  selectableRows: "none",
                  responsive: smallScreen?"scroll":'simple',
                  pagination: false,
                  download: false,
                  viewColumns: false,
                  filter: true,
                  print: false,
                }}
              />
            )}
      </div>
    </div>
    </>
  );
};
export default ActivityReport;
