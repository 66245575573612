import {
  GET_UPDATES_DETAILS,
  SUCCESS_GET_UPDATES_DETAILS,
  ERROR_GET_UPDATES_DETAILS,
} from "./overviewActionTypes";
import axios from "axios";

const successActionHelper = data => {
  return {
    type: SUCCESS_GET_UPDATES_DETAILS,
    payload: data,
  };
};
const getActionHelper = () => {
  return {
    type: GET_UPDATES_DETAILS,
    loading: true,
  };
};

export const getAllUpdatesDetails = (subprojectId, pageNumber) => async dispatch => {
  //
  //   dispatch(loadingActionHelper());
  try {

    dispatch(getActionHelper());
    const res = await axios.get(
      `${global.config.backendURL}/overview/getAllUpdatesDetails/${subprojectId}/${pageNumber}`
    );
    console.log("ss", res.data);
    // successActionHelper(res.data);
    dispatch(successActionHelper(res.data));
  } catch (err) {
    // errorActionHelper(err);
    // dispatch(errorActionHelper(err.data));
  }
};
