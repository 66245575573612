export const ADD_LOADING = "ADD_LOADING";
export const ADD_MATERIAL = "ADD_MATERIAL";
export const MATERIAL_FAILURE = "MATERIAL_FAILURE";
export const MATERIAL_SUCCESS = "MATERIAL_SUCCESS";
export const MATERIAL_ERROR = "MATERIAL_ERROR";
export const GET_MATERIAL = "GET_MATERIAL";
export const GET_STORE_SUCCESS = "GET_STORE_SUCCESS";
export const GET_STORE_FAILURE = "GET_STORE_ERROR";
export const GET_EQUIPMENT_SUCCESS = "GET_EQUIPMENT_SUCCESS";
export const GET_EQUIPMENT_FAILURE = "GET_EQUIPMENT_ERROR";
export const GET_LABOUR_SUCCESS = "GET_LABOUR_SUCCESS";
export const GET_LABOUR_FAILURE = "GET_LABOUR_ERROR";
export const GET_LABOURFORCE_SUCCESS = "GET_LABOURFORCE_SUCCESS";
export const GET_LABOURFORCE_FAILURE = "GET_LABOURFORCE_ERROR";
export const ADD_ISSUE_HISTORY_LOADING = "ADD_ISSUE_HISTORY_LOADING";
export const ISSUE_HISTORY_SUCCESS = "ISSUE_HISTORY_SUCCESS";
export const ISSUE_HISTORY_ERROR = "ISSUE_HISTORY_ERROR";
export const ADD_ISSUE = "ADD_ISSUE";
export const ADD_MATERIAL_REQ = "ADD_MATERIAL_REQ";
export const MATERIAL_REQ_LOADING = "MATERIAL_REQ_LOADING";
export const MATERIAL_REQ_SUCCESS = "MATERIAL_REQ_SUCCESS";
export const MATERIAL_REQ_ERROR = "MATERIAL_REQ_ERROR";
export const MATERIALS_LIST_LOADING = "MATERIALS_LIST_LOADING";
export const MATERIALS_LIST_FAILURE = "MATERIALS_LIST_FAILURE";
export const MATERIALS_LIST_SUCCESS = "MATERIALS_LIST_SUCCESS";
export const EQUIPMENTS_LIST_LOADING = "EQUIPMENTS_LIST_LOADING";
export const EQUIPMENTS_LIST_FAILURE = "EQUIPMENTS_LIST_FAILURE";
export const EQUIPMENTS_LIST_SUCCESS = "EQUIPMENTS_LIST_SUCCESS";
export const LABOUR_LIST_LOADING = "LABOUR_LIST_LOADING";
export const LABOUR_LIST_FAILURE = "LABOUR_LIST_FAILURE";
export const LABOUR_LIST_SUCCESS = "LABOUR_LIST_SUCCESS";
export const LABOURFORCE_LIST_LOADING = "LABOURFORCE_LIST_LOADING";
export const LABOURFORCE_LIST_FAILURE = "LABOURFORCE_LIST_FAILURE";
export const LABOURFORCE_LIST_SUCCESS = "LABOURFORCE_LIST_SUCCESS";
export const REJECT_MATERIAL_REQ = "REJECT_MATERIAL_REQ";

