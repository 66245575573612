import React, { useState, useEffect } from "react";
import {
  InputLabel,
  TextField,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { Save, Cancel, Add as AddIcon } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Style from "./style.module.scss";
import { isInteger } from "lodash";
import axios from "axios";

const AddMaterial = props => {
  const [open, setOpen] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [toggleText, setToggleText] = useState(true);
  const [activities, setActivities] = useState([]);
  const [activity, setActivity] = useState("");

  const [mainActivities, setMainActivities] = useState([]);
  const [materialList,setMaterialList]=useState([])
  const loading = open && materials.length === 0;

  useEffect(() => {
    if (!loading) {
      return undefined;
    }
    var tempMaterials = new Set();
    (() => {
      props.storeData.map(ele => {
        return tempMaterials.add(ele.materialName);
      });
      setMaterials(Array.from(tempMaterials));
    })();
    console.log("asdasdasdasd", props.storeData);
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setMaterials([]);
    }
   // getActivities();
    getMasterMaterialList();
  }, [open]);

async function getMasterMaterialList(){
  let projectId = await global.config.secureStorage.getItem("projectId");
  const response = await axios.get(
    `${global.config.backendURL}/project/getmastermaterials/${projectId}`
  );
  let material_list=[];
  response.data.data.forEach(element => {
    console.log("magad",element.materialName)
      material_list.push(element.materialName)
  });
  setMaterialList(material_list)
}
  //   const handleMainActivityChange = (event) =>{
  //     const value = event.target.value;
  //     const id = mainActivities.findIndex(act=>{
  //       return act.title === value;
  //     });
  //     console.log(id)
  //     if(id>=0){
  //      const activity = mainActivities[id].activities;
  //      console.log(activity);
  //      setActivities(activity);
  //      props.handleMainActivity(event);
  //     }
  //  }

  return (
    <div>
      <section className={Style.create__material}>
        <form onSubmit={props.saveMaterial}>
          <center>
            <Typography variant="overline" style={{ fontSize: "1.5em" }}>
              Add to Material
            </Typography>
          </center>
          <br />
          <>
            <Grid container>
              <Grid item md={12} xs={12}>
                 <FormControl fullWidth>
                 <InputLabel id="activity">Material</InputLabel>
                <Select
              labelId="Material"
              id="Material"
              value={props.materialName}
              label="Material"
              onChange={event => {
                props.handleMaterialChange(event);
              }}

              required
            >
              {materialList.map(item => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
            </FormControl>
              </Grid>
            </Grid>
          </>
          <br />
          {/* {projectType === "Infrastructure"?
          <>
          <FormControl fullWidth>
            <InputLabel id="activity">Main Activity</InputLabel>

            <Select
              labelId="mainActivity"
              id="mainActivity"
              value={props.mainActivity}
              label="mainActivity"
              onChange={event => {
                handleMainActivityChange(event);
              }}
              required
            >
              {mainActivities.map(item => (
                <MenuItem value={item.title}>{item.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <br />
          </>
          :''
          } */}
          <FormControl fullWidth>
            <InputLabel id="activity">Activity</InputLabel>
            <Select
              labelId="activity"
              id="activity"
              value={props.activity}
              label="activity"
              onChange={event => {
                props.handleActivity(event);
              }}
              required
            >
              {props.activities.map((item,i) => (
                <MenuItem value={`${item.title};${i}`}>{item.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <br/>
          <br/>
          <TextField
            name="materialQuantity"
            id="materialQuantity"
            variant="outlined"
            onChange={event => {
              if (isInteger(parseInt(event.target.value))) {
                props.handleChange(event);
              }
            }}
            label="Quantity"
            value={props.materialQuantity}
            type="number"
            required
            fullWidth
          />
          <br />
          <br />
          <TextField
            name="materialUnit"
            id="materialUnit"
            variant="outlined"
            onKeyPress={event =>
              (event.charCode >= 65 && event.charCode <= 90) ||
              (event.charCode >= 97 && event.charCode <= 122)
            }
            onChange={event => {
              props.handleChange(event);
            }}
            label="Unit"
            value={props.materialUnit}
            type="text"
            required
            fullWidth
          />
          <br />
          <br />
          <TextField
            name="materialRate"
            id="materialRate"
            variant="outlined"
            onChange={event => {
              if (isInteger(parseInt(event.target.value))) {
                props.handleChange(event);
              }
            }}
            label="Rate"
            value={props.materialRate}
            type="number"
            required
            fullWidth
          />
          <br />
          <br />
          <TextField
            name="materialAmount"
            id="materialAmount"
            variant="outlined"
            onChange={event => {
              if (isInteger(parseInt(event.target.value))) {
                props.handleChange(event);
              }
            }}
            label="Amount"
            value={props.materialRate * props.materialQuantity}
            type="number"
            disabled
            required
            fullWidth
          />
          <br />
          <br />

          <Grid justify="center" container>
            <Grid className="mr-4" item>
              <Button
                variant="contained"
                size="large"
                type="submit"
                startIcon={<Save />}
              >
                Save
              </Button>
            </Grid>
            {/* <Grid item>
              <Button
                color="secondary"
                size="large"
                onClick={props.toggleCreateNewMaterial}
                startIcon={<Cancel />}
              >
                Cancel
              </Button>
            </Grid> */}
          </Grid>
        </form>
      </section>
    </div>
  );
};
export default AddMaterial;
