import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

export class BarchartData extends Component {
  createDataset = (props) =>{
    const arr = [{
      label: props.percentageLabel,
      data: props.percentage,
      backgroundColor: ["#345BAE"],
    }]

    if(props.percentage1) arr.push({
      label: props.percentage1Label,
      data: props.percentage1,
      backgroundColor: ["#eea47fff"],
    });
    return arr;
  }
  constructor(props) {
    console.log(props.accNames, props.percentage, props.percentage1);
    Chart.register(...registerables);
    // console.log(iterList);
    super(props);
    // const isAddVisible = roles.find(item => item == role) ? true : false;
    this.state = {
      chartData: {
        labels: props.accNames,
        datasets: this.createDataset(props),
      },
    };
  }
  render() {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          padding: "10px",
        }}
      >
        <Bar
          data={this.state.chartData}
          options={
            { 
              maintainAspectRatio: false, 
              indexAxis:'y', 
              responsive:true, 
              scales: {
                x: {
                  stacked: true,
                  display:false
                },
                y: {
                  stacked: true,
                  display:false
                }
              } 
            }
          }
          height={200}
          width={50}
        />
      </div>
    );
  }
}

export default BarchartData;
