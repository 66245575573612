import React, { Component } from "react";
import {
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import AddActivity from "./AddActivity";
import NonEditMainList from "./NonEditMainList";
import EditMainList from "./EditMainList";
import ConfirmationDialog from './ConfirmationDialog'
import Notifications from "react-notification-system-redux";

import {
  ChangeHistory as Change,
  Add as AddIcon,
  CloudDownload,
  CloudUpload,
  Edit,
  RowingOutlined,
} from "@material-ui/icons";
import {
  getDefaultActivities,
  updateDefaultActivities,
  updateTaskActivityDetails,
  updateActivityName,
} from "../../Redux/activityRedux/activityAction";
import IconButton from "@material-ui/core/IconButton";
import Loading from "../../Components/Loading";
import AddRoadActivity from "./AddRoadActivity";
import TableActivities from "./TableActivities";
import axios from "axios";
import Papa from "papaparse";
import { CSVLink } from "react-csv";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const iterList = require("./defaultActivities/defaultArray");
class Activities extends Component {
  constructor(props) {
    // console.log(iterList);
    super(props);
    const role = global.config.secureStorage.getItem("role");
    const roles = ["project_manager", "planning_manager", "senior_engineer", "chief_engineer", "super_admin"];
    const isAddVisible = roles.find(item => item == role) ? true : false;
    this.state = {
      modalOpen: false,
      roadModalOpen: false,
      activityName: "",
      subActivityName: "",
      roadActivityName: "",
      isEditRoadActivity: false,
      subProject: {},
      changeAct: false,
      loading: true,
      isFetched: false,
      defaultActivity: [],
      activeActivityIndex: 0,
      expanded: "panel1",
      percentage: [],
      alertSubActivity: false,
      alertSubActivityField: "",
      isAddVisible: isAddVisible,
      tableData: [],
      isOpenTable: false,
      projectType: "",
      subActivityPercentage: [],
      isDialogOpen: false,
      export_data:[],
      UpdatedAccImport:[],
      ImportAccErrors:[],
      isImportConflict:false,
      importedSubAcc:{},
      OpenImportDialog:false,
      updated_default_acc:false,
       sameLinkacc:[]
    };
  }
  guidGenerator = () => {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  };
  findTaskConflict = async (sub_acc_content) =>{
      const subprojectId = global.config.secureStorage.getItem("subprojectId");
      const subAccNames=Object.keys(sub_acc_content)
      const new_subac=sub_acc_content
     for(let index=0;index< subAccNames.length;index++)
      { 
        if(this.state.sameLinkacc.indexOf(subAccNames[index]) !== -1)
        {
            continue;
        }

      const response = await axios.post(
        `${global.config.backendURL}/activities/getSubActivity`,
        {
          subprojectId,
          subActivityName: subAccNames[index]
        }
      )
    //  console.log(response.data)
      if(sub_acc_content[subAccNames[index]])
      sub_acc_content[subAccNames[index]].forEach((task,importIndex) =>{
          if(response.data[subAccNames[index]])
        response.data[subAccNames[index]].forEach(ftask =>{
            if(task.name === ftask.name){
                ftask["subTask"].forEach(fstask =>{
                 let subtaskrepeat=false
                if(task["subTask"])
                  task["subTask"].forEach((stask,sub_row) =>{
                    if(fstask.name=== stask.name) 
                    {
                     new_subac[subAccNames[index]][importIndex]["subTask"][sub_row].percentage=fstask.percentage
                      subtaskrepeat=true;
                    }        
                })
                if(!subtaskrepeat){
                if(!new_subac[subAccNames[index]]["subTask"])
                new_subac[subAccNames[index]]["subTask"]=[(fstask)]
                else 
                new_subac[subAccNames[index]]["subTask"].push(fstask)
                }
                
              })

                 this.setState({// 
                ImportAccErrors:[
                  ...this.state.ImportAccErrors,
                  {
                     "error_text":"task already exists",
                     "rowNo":task.rowNo
                  }],
                  
              })
            }
        })
      })
      if(response.data[subAccNames[index]])
      response.data[subAccNames[index]].forEach(ftask =>{
        let taskrepeat=false
        if(sub_acc_content[subAccNames[index]])
        sub_acc_content[subAccNames[index]].forEach(task =>{
            if(task.name === ftask.name){
             taskrepeat=true

            }
        })
        if(!taskrepeat)
        {
          if(!new_subac[subAccNames[index]])
          new_subac[subAccNames[index]]=[ftask]
          else 
          new_subac[subAccNames[index]].push(ftask)

             taskrepeat=false
        }
      })
      new_subac[subAccNames[index]].forEach(ttask =>{
        delete(ttask.rowNo)

      })

     }
     
     this.setState({
      importedSubAcc:{...new_subac}
     })
  }
  addMoreAcctivity = async (activityName, subActivityName ) => {
    let deff = [...this.state.UpdatedAccImport];
    let allSub = [];
    let link= this.makeValidLink(subActivityName);
    let break_loop=false;
    deff.forEach((acc,index1) => {
      acc.content.forEach((subacc,index2) => {
    
        if(subacc.link=== link)
        {
          if(subActivityName !== subacc.subTitle)
          {
            this.setState({
              isImportConflict:true,
              sameLinkacc:[...this.state.sameLinkacc,link]
            })

          }
         
            let new_content=deff[index1].content
            new_content.splice(index2,1);
            deff[index1].content=new_content
           break_loop=true;
           return
        }
        allSub.push(subacc.link);
      });
      if(break_loop) return
    });
   
      let newActivity = [...this.state.UpdatedAccImport];
     
        let flag = false;
        let newSubActivity = [];
        for (let i = 0; i < this.state.UpdatedAccImport.length; i++)
          if (this.state.UpdatedAccImport[i].title === activityName) {
            if (subActivityName === "") {
              return;
            }
            newSubActivity = [...this.state.UpdatedAccImport[i].content];
            if(newSubActivity.length===1 && newSubActivity[0].link==='null')
            newSubActivity.splice(0,1);
            newSubActivity.push({
              visibility: true,
              subTitle: subActivityName,
              link,
            });
            newActivity[i].content = newSubActivity;
            flag = true;
            break;
          }
        if (!flag) {
          let mainLink = this.makeValidLink(activityName);
          newSubActivity.push({
            visibility: true,
            subTitle: subActivityName !== "" ? subActivityName : "Update Here",
            link: subActivityName !== "" ? link : "null",
          });
          newActivity.push({
            visibility: true,
            title: activityName,
            content: newSubActivity,
            activity: mainLink,
          });
        }
      this.setState({
        UpdatedAccImport: newActivity,
      });
     
    
  };

  changeHandler = event => {
    console.log("inside change handler")
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {
        const rowsArray = [];
        const valuesArray = [];

        results.data.map(d => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        let updated_default_acc = [];
        let curr_obj = [];// holds a activity
        let content = [];// holds sub activity
        let update_acc = {};// this is what in activities collections
        let curr_sub_acc = [];// holds sub acc all task
        let curr_task = {}; // holds curr sub acc single task
        let curr_subtasks = [];// holds subtasks
        let first_time = true;
        let first_time_sub = true;
        let key_name_sub = "";
        //await this.props.getDefaultActivities();
          
       
        this.setState({
          UpdatedAccImport:this.state.defaultActivity,
          loading:true
        })
        valuesArray.map( async (row,rowNo) => {
          let itemNo=0;
          let startOfSrno=row[0][0];
          for(let k=0;k<row[0].length-1;k++)
          {
           if(row[0][k] ==='.' && row[0][k] !== row[0][k+1])
           {
            if(startOfSrno==='.') startOfSrno=row[0][k+1]
            else
            itemNo++;
           }
          }
         
          if (row[0].length > 0 && itemNo===0) {
            if (!first_time) {// as first row is acc no need to push
              curr_obj = { ...curr_obj, content };
              updated_default_acc.push(curr_obj);
             curr_obj.content.forEach( sub_acc =>{//adding sub activity in activity
              this.addMoreAcctivity(curr_obj.title,sub_acc.subTitle)
              if(this.state.isImportConflict)
              {
                this.setState({
                  ImportAccErrors:[
                    ...this.state.ImportAccErrors,
                    {
                       "error_text":"sub activity will be overwritten",
                       "rowNo":sub_acc.row_no+2
                    }],
                    isImportConflict:false,
                })
              }
             })
             if(curr_obj.content.length===0)
             this.addMoreAcctivity(curr_obj.title,"")
            }
            content = [];
            curr_obj = {};
            curr_obj = {
              ...curr_obj,
              title: row[1],
              activity:this.makeValidLink(row[1]),
              visiblity: true,
            };
          } else if (row[0].length > 1 && itemNo===1) {
            
            content.push({ // this is sub activity
              subTitle: row[1],
              link:  this.makeValidLink(row[1]),
              visiblity: true,
              row_no:rowNo
            });
            if (first_time_sub) {
              key_name_sub = row[1];
              first_time_sub = false;
            } else {
              curr_task["subTask"] = curr_subtasks;
              if(curr_task["name"] != null){
              curr_sub_acc.push(curr_task);
              update_acc[this.makeValidLink(key_name_sub)] = curr_sub_acc;
              }
              curr_subtasks = [];
              curr_task = {};
              curr_sub_acc = [];
              key_name_sub = row[1];
            }
          } else if (itemNo === 2) {
          //  console.log("row no as task ",rowNo)
            if (curr_task["name"] == null) {
              curr_task = {
                ...curr_task,
                name: row[1],
                taskEstimateStart:new Date(row[2]),
                taskEstimateEnd: new Date(row[3]),
                taskActualStart: "",
                taskActualEnd: "",
                rowNo:rowNo+2
              };

             // console.log("task at ", curr_task)

            } else {
              curr_task["subTask"] = curr_subtasks;
              curr_sub_acc.push(curr_task);
              curr_subtasks = [];
              curr_task = {};
            }
            
            curr_task = {
              ...curr_task,
              name: row[1],
              taskEstimateStart: new Date(row[2]),
              taskEstimateEnd: new Date(row[3]),
              taskActualStart: "",
              taskActualEnd: "",
              rowNo: rowNo+2
            };
          } else if(itemNo===3) {
            curr_subtasks.push({
              name: row[1],
              percentage: 0,
              randomid:  this.guidGenerator(),
            });
          }
          first_time = false;
        });
        if (!first_time) {
          curr_obj = { ...curr_obj, content };
          updated_default_acc.push(curr_obj);
          curr_obj.content.forEach( sub_acc =>{
            this.addMoreAcctivity(curr_obj.title,sub_acc.subTitle)
            if(this.state.isImportConflict)
            {
              this.setState({
                ImportAccErrors:[
                  ...this.state.ImportAccErrors,
                  {
                     "error_text":"sub activity will be overwritten",
                     "rowNo":sub_acc.row_no +2
                  }],
                  isImportConflict:false,
              })
            }
           })
           if(curr_obj.content.length===0)
             this.addMoreAcctivity(curr_obj.title,"")     
           }
        if (!first_time_sub) {
          curr_task["subTask"] = curr_subtasks;
          if(curr_task["name"] != null){
            curr_sub_acc.push(curr_task);
            update_acc[this.makeValidLink(key_name_sub)] = curr_sub_acc;
            }
              
              curr_subtasks = [];
              curr_task = {};
         
        }
       let mmm=update_acc
     await   this.findTaskConflict(mmm)

        this.setState({
          OpenImportDialog:true,
          loading:false
        })
   

      }.bind(this),
    });
  };
  overwriteImport = () =>{
   
      try {
        console.log("this",this.state.UpdatedAccImport)

          const subprojectId = global.config.secureStorage.getItem("subprojectId");

          const res = axios.post(
            `${global.config.backendURL}/activities/updateActivitycsv`,
            {
             all_data:this.state.importedSubAcc,
             subprojectId
            }
          );
          const newActivity= this.state.UpdatedAccImport
          this.props.updateDefaultActivities(
            this.props.defaultActivity._id,
            newActivity
          );
        } catch (err) {
       console.log("errors",err)
        }
        this.setState({
          defaultActivity:this.state.UpdatedAccImport,
          isImportConflict:false,
          OpenImportDialog:false,
          ImportAccErrors:[],
           sameLinkacc:[],
          importedSubAcc:{},
          UpdatedAccImport:[],
          updated_default_acc:true
        })
     //   window.location.reload();
  }
 cancelImport = async () =>{
    await this.props.getDefaultActivities();
    console.log("img chec",this.props.defaultActivity.data)
    this.setState({
      defaultActivity:this.props.defaultActivity.data,
      isImportConflict:false,
      OpenImportDialog:false,
      ImportAccErrors:[],
     sameLinkacc:[],
      importedSubAcc:{},
      UpdatedAccImport:[]
    })
    console.log("this is ",this.state.defaultActivity)
   // window.location.reload();
       
  }
  handleExport =()=>{
    const subprojectId = global.config.secureStorage.getItem("subprojectId");
    const projectId = global.config.secureStorage.getItem("projectId");
    axios
    .get(
      `${global.config.backendURL}/activities/getActivity_exportcsv/${projectId}/${subprojectId}`
    )
    .then(async res => {
      this.setState({
        export_data:res.data
      })
    })

  }
 clearExportdata = () =>{
  this.setState({
    export_data:[]
  })
 }
  handleChange = (panel, index) => (event, newExpanded) => {
    // this.setState({ activeActivityIndex: index });
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };
  async componentDidMount() {
    try {
      await this.props.getDefaultActivities();
      console.log("way bfoe",this.props.defaultActivity.data)
      this.setState({
        defaultActivity: this.props.defaultActivity.data,
        
        percentage: this.props.percentage.per,
        percentageActivity: this.props.percentage.accName,
        subActivityPercentage: this.props.percentage.subActivityPercentage,
        loading: !this.state.loading,
      });
      this.fetchData();
    } catch (error) {
      //
    }
  }

  ChangeDialogStatus = () => {
    this.setState({ isDialogOpen: true });
  };

  componentDidUpdate(pP, pS, sS) {

    if (pP.defaultActivity !== this.props.defaultActivity) {
      console.log('ss')
      this.setState({
        defaultActivity: this.props.defaultActivity.data,
        percentage: this.props.percentage.per,
        percentageActivity: this.props.percentage.accName,
        subActivityPercentage: this.props.percentage.subActivityPercentage,
      });
    }

  }


  submitActivityList = () => {
    this.setState({ isDialogOpen: false });
    this.props.updateDefaultActivities(
      this.props.defaultActivity._id,
      this.state.defaultActivity
    );
    this.props.getDefaultActivities();
    this.changeAct();
  };

  fetchData = () => {
    const projectId = global.config.secureStorage.getItem("projectId");
    const id = global.config.secureStorage.getItem("subprojectId");
    let tempactivities = [];
    let tempfloorwisedata = [];
    axios
      .get(
        `${global.config.backendURL}/activities/getDefaultActivities/${projectId}`
      )
      .then(async res => {
        tempactivities = res.data.data;
        // axios
        //   .get(
        //     `${global.config.backendURL}/activities/getFloorsPercentage/${projectId}/${id}`
        //   )
        //   .then(async res => {
        let tempA = [];
        let tempF = [];
        let temparr = [];
        // tempfloorwisedata = res.data;
        let flatData;
        try {
          flatData = await axios.post(
            `${global.config.backendURL}/activities/getAllActivity`,
            { subprojectId: id }
          );
        } catch (er) {
          console.log(er);
        }
        tempactivities.map(activity => {
          activity.content.map(sub => {
            let flats = {};
            if (sub.link in flatData.data) {
              for (let [key, value] of Object.entries(
                flatData.data[sub.link]
              )) {
                flats[key] = value.map(
                  (ele, id) => ele === "complete" && id + 1
                );
              }
            } else if (activity.activity in flatData.data) {
              for (let [key, value] of Object.entries(
                flatData.data[activity.activity]
              )) {
                flats[key] = value.map(
                  (ele, id) => ele === "complete" && id + 1
                );
              }
            }
            if (sub.subTitle != "Update Here") {
              tempA.push({
                activity: activity.title,
                subactivity: sub.subTitle,
                flats,
              });
            } else {
              tempA.push({
                activity: activity.title,
                subactivity: activity.title,
                flats,
              });
            }
          });
        });
        // tempA.map((ele, index) => {
        //   temparr = [];
        //   tempfloorwisedata.map((floor, i) => {
        //     if (floor[index].percentage !== 0) {
        //       temparr.push({
        //         status: floor[index].percentage,
        //         flats: ele.flats[i],
        //       });
        //     } else {
        //       temparr.push({
        //         status: floor[index].percentage,
        //         flats: [],
        //       });
        //     }
        //   });
        //   tempF.push({
        //     activity: ele.activity,
        //     subactivity: ele.subactivity,
        //     floors: temparr,
        //   });
        // });
        tempA = [];
        tempactivities.map(ele => {
          temparr = [];
          tempF.map(item => {
            if (ele.title == item.activity) {
              temparr.push({
                title: item.subactivity,
                floors: item.floors,
              });
            }
          });
          tempA.push({ activity: ele.title, subactivities: temparr });
        });
        this.setState({ tableData: tempA });
      })
      //     .catch(e => console.log(e));
      // })
      .catch(e => console.log(e));
  };

  handleModalOpen = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };
  handleModalRoadOpen = () => {
    this.setState({
      roadModalOpen: !this.state.roadModalOpen,
    });
  };

  handleAutoComplete = (event, value) => {
    this.setState({
      activityName: value,
    });
  };

  handleActivityChange = event => {
    if (this.state.alertSubActivity) {
      this.setState({
        alertSubActivity: !this.state.alertSubActivity,
        alertSubActivityField: "",
      });
    }
    // this.getAllSubAc();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  getAllSubAc = () => {
    let deff = [...this.state.defaultActivity];
    // deff = deff[this.state.activeActivityIndex].data;
    let allSub = [];
    deff.forEach(acc => {
      acc.content.forEach(subacc => {
        allSub.push(subacc.link);
      });
    });
    return allSub;
  };

  makeValidLink = activity => {
    const regex = /[.,\\/#!$%\\^&\\*;:{}=\-_`~()]/g;
    return activity.toLowerCase().replace(regex, " ").split(" ").join("-");
  };

  submitActivity = () => {
    const { activityName, subActivityName } = this.state;
    if (activityName == "") {
      this.setState({
        activityName: "",
        subActivityName: "",
      });
      // //
      this.handleModalOpen();
      return;
    }

    let allsub = this.getAllSubAc();

    let matchFound = false;
    allsub.forEach(element => {
      let newlink = this.makeValidLink(subActivityName);

      if (element == newlink) {
        this.setState({
          alertSubActivity: !this.state.alertSubActivity,
          alertSubActivityField: element + " Sub Activity Already Exists",
        });
        matchFound = true;
      }
    });
    if (!matchFound) {
      let newActivity = [...this.state.defaultActivity];
      let link = this.makeValidLink(subActivityName);
      if (typeof activityName === "string") {
        let flag = false;
        let newSubActivity = [];
        for (let i = 0; i < this.state.defaultActivity.length; i++)
          if (this.state.defaultActivity[i].title === activityName) {
            if (subActivityName === "") {
              this.setState({
                alertSubActivity: !this.state.alertSubActivity,
                alertSubActivityField: "Please Fill sub activity name",
              });
              return;
            }
            newSubActivity = [...this.state.defaultActivity[i].content];
            newSubActivity.push({
              visibility: true,
              subTitle: subActivityName,
              link,
            });
            newActivity[i].content = newSubActivity;
            flag = true;
            break;
          }
        if (!flag) {
          let mainLink = this.makeValidLink(activityName);
          newSubActivity.push({
            visibility: true,
            subTitle: subActivityName !== "" ? subActivityName : "Update Here",
            link: subActivityName !== "" ? link : "null",
          });
          newActivity.push({
            visibility: true,
            title: activityName,
            content: newSubActivity,
            activity: mainLink,
          });
        }
      } else {
        let newSubActivity = [];
        if (subActivityName === "") {
          this.setState({
            alertSubActivity: !this.state.alertSubActivity,
            alertSubActivityField: "Please Fill sub activity name",
          });
          return;
        }
        for (let i = 0; i < this.state.defaultActivity.length; i++)
          if (this.state.defaultActivity[i].title === activityName.title) {
            if (
              activityName.content.length === 1 &&
              activityName.content[0].link === "null"
            ) {
              newSubActivity.push({
                visibility: true,
                subTitle: subActivityName,
                link,
              });
            } else {
              newSubActivity = [...this.state.defaultActivity[i].content];
              newSubActivity.push({
                visibility: true,
                subTitle: subActivityName,
                link,
              });
            }
            activityName.content = newSubActivity;
            newActivity[i] = activityName;
            break;
          }
      }
      this.setState({
        defaultActivity: newActivity,
      });
      this.props.updateDefaultActivities(
        this.props.defaultActivity._id,
        newActivity
      );
      this.setState({
        activityName: "",
        subActivityName: "",
      });
      // //
      this.handleModalOpen();
    }
  };

  handleRoadEdit = name => {
    this.setState({
      roadActivityName: name,
      isEditRoadActivity: true,
    });

    this.handleModalRoadOpen();
  };
  EditRoadActivity = () => {
    let newRoad = this.state.defaultActivity;
    newRoad[this.state.activeActivityIndex] = {
      name: this.state.roadActivityName,
      data: iterList,
    };
    let newActivity = newRoad;

    this.props.updateDefaultActivities(
      this.props.defaultActivity._id,
      newActivity
    );
    this.setState({
      roadActivityName: "",
      isEditRoadActivity: false,
    });
    this.handleModalRoadOpen();
  };
  submitRoadActivity = () => {
    let newRoad = this.state.defaultActivity;
    newRoad[newRoad.length] = {
      name: this.state.roadActivityName,
      data: iterList,
    };
    let newActivity = newRoad;
    // console.log(newRoad);
    this.props.updateDefaultActivities(
      this.props.defaultActivity._id,
      newActivity
    );
    this.setState({
      roadActivityName: "",
    });
    this.handleModalRoadOpen();
  };

  changeAct = async () => {
     await this.props.getDefaultActivities();
      this.setState({
        defaultActivity:this.props.defaultActivity.data
      })
    this.setState({ changeAct: !this.state.changeAct, isDialogOpen: false });
  };

  updateSubActivityNameUI = (activityIndex, subActivityIndex, value) => {
    var tempArray = [...this.state.defaultActivity];
    if (subActivityIndex == -1) {
      tempArray[activityIndex]["title"] = value;
    } else {
      tempArray[activityIndex]["content"][subActivityIndex].subTitle = value;
      tempArray[activityIndex]["content"][subActivityIndex].link = this.makeValidLink(value);
    }
    let newAcc = [...this.state.defaultActivity];
    newAcc = tempArray;
    this.setState({
      defaultActivity: newAcc,
    });


    var projectId = global.config.secureStorage.getItem("projectId");
    var subprojectId = global.config.secureStorage.getItem("subprojectId");
    this.props.updateActivityName({
      projectId,
      subprojectId,
      activityIndex: activityIndex,
      subActivityIndex: subActivityIndex,
      title: value,
    });


    // this.changeAct();
  };

  updateMainList = (event, index) => {
    let tempArray = [...this.state.defaultActivity];
    let prev = tempArray[index].visibility;
    tempArray[index].visibility = !prev;
    let newAcc = [...this.state.defaultActivity];
    newAcc = tempArray;
    this.setState({
      defaultActivity: newAcc,
    });
  };

  updateSubList = (event, index, subIndex) => {
    var tempArray = [...this.state.defaultActivity];
    let prev = tempArray[index].content[subIndex].visibility;
    tempArray[index].content[subIndex].visibility = !prev;
    let newAcc = [...this.state.defaultActivity];
    newAcc = tempArray;
    this.setState({
      defaultActivity: newAcc,
    });
  };

  toggleCloseTable = () => {
    this.setState({ isOpenTable: !this.state.isOpenTable });
  };

  render() {
    if (this.props.loading) {
      return <Loading loading={this.props.loading} />;
    }
    const { isOpenTable } = this.state;

    if (isOpenTable) {
      return (
        <TableActivities
          tableData={this.state.tableData}
          toggleCloseTable={this.toggleCloseTable}
        />
      );
    }

    return (
      <div>
         {this.props.notifications && (
          <Notifications notifications={this.props.notifications} />
        )}
        <div>
          <AddActivity
            alertSubActivity={this.state.alertSubActivity}
            alertSubActivityField={this.state.alertSubActivityField}
            data={this.state.defaultActivity}
            activityName={this.state.activityName}
            handleAutoComplete={this.handleAutoComplete}
            open={this.state.modalOpen}
            handleModalOpen={this.handleModalOpen}
            submitActivity={this.submitActivity}
            handleActivityChange={this.handleActivityChange}
          />
        </div>
        {this.state.isDialogOpen && (
          <ConfirmationDialog
            isDialogOpen={this.state.isDialogOpen}
            submitActivityList={this.submitActivityList}
            changeAct={this.changeAct}
          />
        )}
        {
          this.state.OpenImportDialog  &&
          <div>
               <ConfirmationDialog
                isImportConflict={this.state.OpenImportDialog}
                overwriteImport={this.overwriteImport}
                cancelImport={this.cancelImport}
                ImportAccErrors={this.state.ImportAccErrors}
         />
          </div>
        }
        {this.state.isAddVisible && (
          <Grid
            container
            direction="row"
            style={{ marginTop: "2em" }}
            justify="center"
          >
            <Grid item>
              <Button
                className="m-2"
                color="primary"
                onClick={this.handleModalOpen}
                startIcon={<AddIcon />}
              >
                Add Activity
              </Button>
            </Grid>

            {!this.state.changeAct && (
              <Grid item>
                <Button
                  className="m-2"
                  onClick={this.changeAct}
                  startIcon={<Change />}
                >
                  Modify Activities
                </Button>
              </Grid>
            )}
            {/* <Grid item>
              <Button
                className="m-2"
                style={{ backgroundColor: "#4a934a" }}
                onClick={this.toggleCloseTable}
                startIcon={<CloudDownload />}
              >
                Generate PDF
              </Button>
            </Grid> */}
           <Grid
            container
            direction="row"
            style={{ marginTop: "2em" }}
            justify="center"
          >
          <Button
          className="m-2"
            variant="contained"
            component="label"
          >
             <input
            type="file"
            name="file"
            onChange={this.changeHandler}
            accept=".csv"
            hidden
          />
          Import Activities
          </Button>
          
          <Button
                className="m-2"
                color="primary"
                onClick={this.handleExport}
              >
                Export Activity
              </Button>
              {(this.state && this.state.export_data.length>0) &&
              (
             <Button
              className="m-2"
              color="secondary"
              startIcon={<CloudDownload />}
              >
              <CSVLink 
              onClick={this.clearExportdata}
              style={{ color:"white" }}
              data={this.state.export_data} headers={ [
             { label: "Sr no", key: "Sr.no" },
             { label: "Activity", key: "activity" },
             { label: "EST. START DATE", key: "est_start_date" },
             { label: "EST. END DATE", key: "est_end_date" }
             ]}>
              Download file
             </CSVLink>
              </Button>
              )}
              
            </Grid>
          </Grid>
        )}
        <div className="container mt-2">
          {this.state.loading ? (
            <Loading loading={this.state.loading} />
          ) : !this.state.changeAct ? (

            <NonEditMainList
              defaultActivity={this.state.defaultActivity}
              percentage={this.state.percentage}
              subActivityPercentage={this.state.subActivityPercentage}
              percentageActivity={this.state.percentageActivity}
            />


          ) : (
            <EditMainList
              // updateActivityName={this.props.updateActivityName}
              defaultActivity={this.state.defaultActivity}
              changeAct={this.changeAct}
              updateMainList={this.updateMainList}
              updateSubList={this.updateSubList}
              submitActivityList={this.submitActivityList}
              updateSubActivityNameUI={this.updateSubActivityNameUI}
              ChangeDialogStatus={this.ChangeDialogStatus}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log(state.activityReducer.defaultActivity);
  return {
    isUpdateSuccess: state.isUpdateSuccess,
    notifications: state.notifications,
    defaultActivity: state.activityReducer.defaultActivity,
    percentage: state.activityReducer.percentage,
    loading: state.activityReducer.loading,
    monitoring: state.monitoringReducer.monitoring,
  };
};

export default connect(mapStateToProps, {
  getDefaultActivities,
  updateDefaultActivities,
  updateActivityName,
})(Activities);
