import React, { useState, useEffect } from "react";

import { useTheme } from "@material-ui/core/styles";
import {
  makeStyles,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";
import { connect } from "react-redux";

import { getAllUpdatesDetails } from "../../Redux/overviewRedux/overviewAction";
import Loading from "../../Components/Loading";

import DialogBox from "../../Components/Modal/DialogBox";
const useStyles = makeStyles({
  btn: {
    padding: 20,
    backgroundColor: "#333",
    color: "#fff",
  },
  title: {
    backgroundColor: "#345BAE",
    padding: "11px 10px",
    color: "#fff",
    borderRadius: 11,
  },
  footer: {
    width: "100%",
    textAlign: "right",
  },
  footerlink: {
    backgroundColor: "#345BAE",
    padding: "11px 10px",
    borderRadius: 11,
    color: "#fff",
  },
  getcol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "100%",
  },
});
function ViewUpdates(props) {
  //   console.log(props.location.state.activityDetails);

  const [loading, setLoading] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelImageUrl, setModelImageUrl] = useState("");

  const [pageNumber, setPageNumber] = useState(0);

  // let updateDataSorted = props.location.state.activityDetails;
  const classes = useStyles();
  const handleShowDialog = url => {
    if (!isModalOpen) setModelImageUrl(url);
    setIsModalOpen(!isModalOpen ? true : false);
  };

  useEffect(() => {
    const subprojectId = global.config.secureStorage.getItem("subprojectId");

    (async () => {
      await props.getAllUpdatesDetails(subprojectId, pageNumber);
    })();

    return () => {
      console.log("This will be logged on unmount");
    };
  }, [pageNumber]);
  if (props.loading) {
    return <Loading loading={props.loading} />;
  }

  return (
    <>
      <div className="container" style={{ marginTop: "20px" }}>
        <Grid container spacing={2}>
          <DialogBox
            handleShowDialog={handleShowDialog}
            isModalOpen={isModalOpen}
            modelImageUrl={modelImageUrl}
          />
          {props.activityDetails
            ? props.activityDetails.records.map((detail, detailIndex) => {
              return (
                <Card
                  variant="outlined"
                  style={{ margin: "5px", width: "100%" }}
                  elevation={3}
                >
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={8} md={8}>
                        <Typography variant="p" className={classes.getcol}>
                          Updated by:
                          {detail.data[0].user}
                          {/* {detail.user} */}
                        </Typography>
                        <Typography variant="p" className={classes.getcol}>
                          Update Date :{detail.data[0].date}
                        </Typography>

                        <Typography variant="p" className={classes.getcol}>
                          Activity Name:
                          {detail.data[0].activityName}
                        </Typography>
                        <Typography variant="p" className={classes.getcol}>
                          Update Percentage:
                          {detail.data[0].updatedPercentage}%
                        </Typography>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ paddingRight: "10px" }}
                          onClick={() => {
                            if (detail.data[0].full_panaroma_url)
                              handleShowDialog(detail.data[0].full_panaroma_url);
                            else handleShowDialog(detail.data[0].panaroma);
                          }}
                        // startIcon={<Info />
                        >
                          View Panaroma
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              );
            })
            : "No Updates"}
          <div
            style={{
              marginTop: 10,
              marginLeft: 10,
            }}
          >
            {props.activityDetails
              ? new Array(props.activityDetails.totalPages)
                .fill(null)
                .map((v, i) => i)
                .map(pageIndex => {
                  return (
                    <Button
                      key={pageIndex}
                      variant="contained"
                      color={pageIndex == pageNumber ? "primary" : "#E0E0E0"}
                      style={{ marginRight: "10px" }}
                      onClick={() => setPageNumber(pageIndex)}
                    >
                      {pageIndex + 1}
                    </Button>
                  );
                })
              : ""}
          </div>
        </Grid>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  console.log(state.overviewReducer.activityDetails);
  return {
    loading: state.overviewReducer.loading,
    activityDetails: state.overviewReducer.activityDetails,
  };
};

export default connect(mapStateToProps, {
  getAllUpdatesDetails,
})(ViewUpdates);
