import React, { Component } from "react";
import EditLabour from "./EditLabour";
import AddLabour from "./AddLabour";
// import { blue } from "@material-ui/core/colors";
import { Add } from "@material-ui/icons";
import Style from "./style.module.scss";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import {
  addMaterial,
  getMaterial,
  updateMaterial,
  deleteMaterial,
  addDeliveryDate,
  getStore,
} from "../../Redux/materialRedux/materialAction";
import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  useMediaQuery,
  DialogContentText,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  withStyles,
  DialogTitle,
  CardContent,
  Typography,
  IconButton,
  Grid,
  Card,
} from "@material-ui/core";
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  Add as AddIcon,
  Edit,
  Delete,
} from "@material-ui/icons";
import Notifications from "react-notification-system-redux";
import axios from "axios";
import SlideTransition from "@material-ui/pickers/views/Calendar/SlideTransition";
// import Typography from "material-ui/styles/typography";
// import { each } from "lodash";

class Display extends Component {
  constructor(props) {
    super(props);
    const role = global.config.secureStorage.getItem("role");
    const editRoles = ["purchase_manager", "project_manager", "super_admin"];
    const isEditable = editRoles.find(item => item == role) ? true : false;
    this.state = {
      activities: [],
      unit: "",
      contractorName: "",
      contractorRate: "",
      items: [],
      contractorAmount: "",
      activity: "",
      isCreateNewContractor: false,
      isEditable: isEditable,
      labourData: [],
      contractorData: [],
      isEditContractor: false,
      editId: "",
      sortedContractor: {},
      contractorIndex: -1,
      activityIndex: -1,
      subAccIndex: -1,
      expanded: "panel1",
    };
  }
  handleExpandChange = panel => (event, newExpanded) => {
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };
  columns = [
    {
      name: "item",
      label: "Item",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "mainActivity",
    //   label: "Main Activity",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    {
      name: "unit",
      label: "unit",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "quantity",
      label: "quantity",
      options: {
        filter: true,
        sort: true,
      },
    },

    // {
    //   name: "number_of_labourers",
    //   label: "Items",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },

    // {
    //   name: "labourRate",
    //   label: "rate",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "rate",
      label: "rate",
      options: {
        filter: true,
        sort: false,
      },
    },
    // {
    //   name: "Edit",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <button
    //           onClick={() => {
    //             this.toggleEditLabour(tableMeta);
    //           }}
    //         >
    //           Edit
    //         </button>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "Delete",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <button onClick={() => this.deleteLabour(tableMeta.rowIndex)}>
    //           Delete
    //         </button>
    //       );
    //     },
    //   },
    // },
  ];
  async getContractorData() {
    let projectId = await global.config.secureStorage.getItem("projectId");
    let curr_sorted_contractor = {};
    try {
      const res = await axios.post(
        `${global.config.backendURL}/labour/getContractor`,
        {
          projectId: projectId,
        }
      );
      console.log("contractor's data is ", res.data);
      res.data.forEach((contractor, i) => {
        let curr_accName=contractor.activity + contractor.subactivity + contractor.taskName
        if (curr_sorted_contractor[contractor.contractorName] === undefined) {
          curr_sorted_contractor[contractor.contractorName] = {};
          curr_sorted_contractor[contractor.contractorName] = {
            [curr_accName]: {
              contractorAmount: contractor.contractorAmount,
              items: contractor.items,
              _id: contractor._id,
              projectId: contractor.projectId,
              index: i,
            },
          };
        } else {
          curr_sorted_contractor[contractor.contractorName] = {
            ...curr_sorted_contractor[contractor.contractorName],
            [curr_accName]: {
              contractorAmount: contractor.contractorAmount,
              items: contractor.items,
              _id: contractor._id,
              projectId: contractor.projectId,
              index: i,
            },
          };
        }
      });
      console.group("new contractor sorted data i s", curr_sorted_contractor);
      this.setState({
        contractorData: res.data,
        sortedContractor: curr_sorted_contractor,
      });
    } catch (err) {
      console.log(err);
    }
  }
  handleContractorChange = event => {
    this.setState({
      contractorName: event.target.value,
      contractorIndex: event.target.value.split(";")[1],
      items: [{ item: "", rate: "", unit: "", quantity: "" }],
      contractorAmount: "",
    });
    
  };

  async componentDidMount() {
    await this.getContractorData();
    this.getActivities();
    this.setState({
      items: [
        {
          item: "",
          rate: "",
          unit: "",
        },
      ],
    });
  }

  handleChange = (event, value = null) => {
    if (value === null) {
      this.setState({
        [event.target.name]: event.target.value,
      });
    } else {
      this.setState({
        labourName: value,
      });
    }
  };
  handleActivity = event => {
    this.setState({
      activity: event.target.value,
      activityIndex: event.target.value.split(";")[1],
      taskCost:"",
      taskName:"",
      taskList:"",
      subAccName:"",
      subAccIndex:""
    });
  };
  // handleSubActivity = async event => {
  //   let new_task;

  //   try {
  //     let index = event.target.value.split(";")[1];
  //     let subAccLink =
  //       this.state.activities[this.state.activityIndex].content[index].link;
  //     const subprojectId = global.config.secureStorage.getItem("subprojectId");
  //     const res = await axios.get(
  //       `${global.config.backendURL}/activities/getActivity?activity=${subAccLink}&subprojectId=${subprojectId}`
  //     );
  //     new_task = res.data;
  //   } catch (err) {
  //     console.log("err is ", err);
  //   }
  //   this.setState({
  //     subAccName: event.target.value,
  //     subAccIndex: event.target.value.split(";")[1],
  //     taskList: new_task,
  //     taskName:"",
  //     taskCost:""
  //   });
  // };
  // handleTaskChange = event => {
  //   let index = event.target.value.split(";")[1];
  //   this.setState({
  //     taskName: event.target.value,
  //     taskCost: this.state.taskList[index].taskCost,
  //   });
  // };
  getActivities = async () => {
    let projectId = await global.config.secureStorage.getItem("projectId");
    const response = await axios.get(
      `${global.config.backendURL}/activities/getDefaultActivities/${projectId}`
    );
    // if(projectType === "Infrastructure"){
    //   const mainActivities = response.data.data.map((mainAct,index)=>{
    //     console.log(mainAct)
    //     return {
    //       id: index,
    //       title : maisnAct.name,
    //       activities : mainAct.data
    //     };
    //   });
    //   setMainActivities(mainActivities);
    // }
    // else{
    //   setActivities(response.data.data[0].data);
    // }
    let final = [];
    response.data.data.forEach(element => {
      if (element.visibility) final.push(element);
    });
    this.setState({
      activities: final,
    });
  };
  // handleMainActivity = event => {
  //   console.log(event)
  //   this.setState({ mainActivity: event.target.value});
  // };

  toggleEditContractor = async c_index => {
    let curr_contractor = [...this.state.contractorData];
    console.log("curr contractors ",curr_contractor)
    let projectId = await global.config.secureStorage.getItem("projectId");
    let index = 0;
    const response = await axios.get(
      `${global.config.backendURL}/stores/getMasterListContractorRA/${projectId}`
    );
    response.data.forEach((con, i) => {
      if (con.contractorName === curr_contractor[c_index].contractorName) {
        index = i;

        return;
      }
    });
    // return
    let new_items = [...curr_contractor[c_index].items];
    curr_contractor[c_index].items.forEach((item, i) => {
      new_items[i] = {
        ...item,
        item: item.item + ";" + item.unit,
      };
    });
    let new_task=[];
    let acc_index;
    let task_index;
    let subacc_index;
    console.log("this state activites is ",this.state.activities,curr_contractor)
    this.state.activities.forEach( async (acc,accIndex) =>{
      console.log("equaliazing them ",acc.title,curr_contractor[c_index].activity)
            if(acc.title === curr_contractor[c_index].activity)
            {
              console.log("acc ndiex is ",accIndex)
              acc_index=accIndex
              const subprojectId = global.config.secureStorage.getItem("subprojectId");
                 acc.content.forEach(async  (subacc,subIndex)=>{
                  if(subacc.subTitle=== curr_contractor[c_index].subactivity)
                  {
                    console.log("indexes are",subIndex)
                    subacc_index=subIndex
                    const res = await axios.get(
                      `${global.config.backendURL}/activities/getActivity?activity=${subacc.link}&subprojectId=${subprojectId}`
                    );
                    let new_taskCost;
                    res.data.forEach((task,taskindex)=>{
                      if(curr_contractor[c_index].taskName===task.name)
                      {
                        new_taskCost=task.taskCost
                        task_index=taskindex
                        return
                      }
                    })
                    this.setState({
                      taskList:res.data,
                      taskName:  curr_contractor[c_index].taskName + ";" + task_index,
                      taskCost:new_taskCost,
                      activityIndex:acc_index,
                      subAccIndex:subacc_index,
                    })
                  }
                 })
            }
    })
    console.log("activires are ",)
    // return
    if (!this.state.isEditContractor) {
      this.setState({
        contractorName: curr_contractor[c_index].contractorName + ";" + index,
        items: new_items,
        contractorIndex: index,
        contractorAmount: curr_contractor[c_index].contractorAmount,
        editId: curr_contractor[c_index]._id,
        activity: curr_contractor[c_index].activity + ";" + acc_index,
        subAccName: curr_contractor[c_index].subactivity + ";" + subacc_index,
        activityIndex:acc_index,
        subAccIndex:subacc_index,
        isEditContractor: !this.state.isEditContractor,
      });
    }
  };
  CancelEditContractor = () => {
    this.setState({
      contractorName: "",
      items: [],
      contractorIndex: 0,
      contractorAmount: "",
      editId: "",
      activity: "",
      isEditContractor: false,
    });
  };
  toggleCreateNewContractor = () => {
    this.setState({
      contractorName: "",
      activity: "",
      unit: "",
      contractorRate: "",
      contractorAmount: "",
      items: [],
      isCreateNewContractor: !this.state.isCreateNewContractor,
    });
  };
  handleAddItem = () => {
    let new_items = this.state.items;
    new_items.push({
      item: "",
      rate: "",
      unit: "",
    });
    this.setState({
      items: new_items,
    });
  };
  handleDeleteItem = index => {
    if (this.state.items.length === 1) {
      alert("atleast one item required");
      return;
    }
    let new_items = this.state.items;
    new_items.splice(index, 1);
    this.setState({
      items: new_items,
    });
  };
  handleItemChange = (event, index) => {
    let new_items = this.state.items;
    if (event.target.name === "item")
      new_items[index] = {
        ...new_items[index],
        [event.target.name]: event.target.value,
        unit: event.target.value.split(";")[1],
      };
    else
      new_items[index] = {
        ...new_items[index],
        [event.target.name]: event.target.value,
      };
    this.handlAmountChange();
    this.setState({
      items: new_items,
    });
  };
  handlAmountChange = () => {
    let amount = 0;
    this.state.items.forEach(item => {
      amount += parseInt(item.rate) * parseInt(item.quantity);
    });
    this.setState({
      contractorAmount: amount,
    });
  };
  deleteContractor = async index => {
    // let singleLabour = [...this.state.labourData];
    // singleLabour = singleLabour[id];
    try {
      const res = await axios.post(
        `${global.config.backendURL}/labour/deleteContractor/${this.state.contractorData[index]._id}`
      );
      let tempData = this.state.contractorData;
      tempData.splice(index, 1);
      let curr_sorted_contractor = {};
      tempData.forEach((contractor, i) => {
        let curr_accName=contractor.activity + contractor.subactivity + contractor.taskName
        if (curr_sorted_contractor[contractor.contractorName] === undefined) {
          curr_sorted_contractor[contractor.contractorName] = {};
         
          curr_sorted_contractor[contractor.contractorName] = {
            [curr_accName]: {
              contractorAmount: contractor.contractorAmount,
              items: contractor.items,
              _id: contractor._id,
              projectId: contractor.projectId,
              index: i,
            },
          };
        } else {
          curr_sorted_contractor[contractor.contractorName] = {
            ...curr_sorted_contractor[contractor.contractorName],
            [curr_accName]: {
              contractorAmount: contractor.contractorAmount,
              items: contractor.items,
              _id: contractor._id,
              projectId: contractor.projectId,
              index: i,
            },
          };
        }
      });
      this.setState({
        contractorName: "",
        activity: "",
        items: [],
        contractorAmount: "",
        contractorData: tempData,
        sortedContractor: curr_sorted_contractor
      });
    } catch (err) {
      console.log(err);
    }
  };

  updateLabour = async event => {
    event.preventDefault();

    let projectId = await global.config.secureStorage.getItem("projectId");
    let new_items = this.state.items;
    new_items.forEach(item => {
      item.item = item.item.split(";")[0];
    });
    this.setState({
      contractorName: this.state.contractorName.split(";")[0],
      items: new_items,
    });
    const finalContractor = {
      projectId,
      contractorName: this.state.contractorName,
     // subactivity: this.state.subAccName.split(";")[0],
     // taskName: this.state.taskName.split(";")[0],
    //  taskCost: this.state.taskCost,
      items: this.state.items,
      activity: this.state.activity.split(";")[0],
      contractorAmount: this.state.contractorAmount,
      _id: this.state.editId,
    };

    const num = Number(this.state.contractorName);
    if (Number.isInteger(num)) {
      alert("Please enter valid contractorName");
      this.setState({ contractorName: "" });
    } else {
      let tempData = [...this.state.contractorData];
      if (this.state.editId != undefined) {
        try {
          const res = await axios.post(
            `${global.config.backendURL}/labour/editContractor/${this.state.editId}`,
            {
              ...finalContractor,
            }
          );
        } catch (err) {
          console.log(err);
        }
        let objIndex = tempData.findIndex(obj => obj._id == this.state.editId);
        tempData[objIndex] = finalContractor;
      }
      let curr_sorted_contractor = {};

      tempData.forEach((contractor, i) => {
        let curr_accName=contractor.activity + contractor.subactivity + contractor.taskName
        if (curr_sorted_contractor[contractor.contractorName] === undefined) {
          curr_sorted_contractor[contractor.contractorName] = {};
         
          curr_sorted_contractor[contractor.contractorName] = {
            [curr_accName]: {
              contractorAmount: contractor.contractorAmount,
              items: contractor.items,
              _id: contractor._id,
              projectId: contractor.projectId,
              index: i,
            },
          };
        } else {
          curr_sorted_contractor[contractor.contractorName] = {
            ...curr_sorted_contractor[contractor.contractorName],
            [curr_accName]: {
              contractorAmount: contractor.contractorAmount,
              items: contractor.items,
              _id: contractor._id,
              projectId: contractor.projectId,
              index: i,
            },
          };
        }
      });
      this.setState({
        editId: "",
        isEditContractor: !this.state.isEditContractor,
        contractorName: "",
        activity: "",
        contractorAmount: "",
        items: [],
        subactivity: "",
        taskName: "",
        taskCost: "",
        taskList:"",
        contractorData: tempData,
        sortedContractor: curr_sorted_contractor
      });
    }
  };
  saveLabour = async event => {
    event.preventDefault();
    let projectId = await global.config.secureStorage.getItem("projectId");
    let new_items = this.state.items;
    new_items.forEach(item => {
      item.item = item.item.split(";")[0];
    });
    this.setState({
      contractorName: this.state.contractorName.split(";")[0],
      items: new_items,
    });
    const finalContractor = {
      projectId,
      contractorName: this.state.contractorName,
      activity: this.state.activity.split(";")[0],
      items: this.state.items,
      contractorAmount: this.state.contractorAmount,
    };
    const num = Number(this.state.contractorName);
    if (Number.isInteger(num)) {
      alert("Please enter valid labour,it cannot be a number");
      this.setState({ contractorName: "" });
    } else {
      try {
        const res = await axios.post(
          `${global.config.backendURL}/labour/addToContractor`,
          {
            ...finalContractor,
          }
        );
      } catch (err) {
        console.log(err);
      }
      let tempData = [...this.state.labourData, finalContractor];
      this.setState({
        labourName: "",
        activity: "",
        unit: "",
        labourRate: "",
        labourAmount: "",
        number_of_labourers: "",
        isCreateNewContractor: !this.state.isCreateNewContractor,
        labourData: tempData,
      });
      window.location.reload();
    }
  };
  render() {
    const { isCreateNewContractor, isEditContractor } = this.state;
    if (isCreateNewContractor) {
      return (
        <AddLabour
          storeData={this.props.storeData}
          activity={this.state.activity}
          unit={this.state.unit}
          items={this.state.items}
          handleChange={this.handleChange}
          contractorName={this.state.contractorName}
          labourRate={this.state.contractorRate}
          labourAmount={this.state.labourAmount}
          labourData={this.state.labourData}
          number_of_labourers={this.state.number_of_labourers}
          saveLabour={this.saveLabour}
          toggleCreateNewContractor={this.toggleCreateNewContractor}
          handleActivity={this.handleActivity}
          handleContractorChange={this.handleContractorChange}
          handleAddItem={this.handleAddItem}
          handleDeleteItem={this.handleDeleteItem}
          handleItemChange={this.handleItemChange}
          contractorIndex={this.state.contractorIndex}
          contractorAmount={this.state.contractorAmount}
          isCreateNewContractor={this.state.isCreateNewContractor}
          activityIndex={this.state.activityIndex}
          handleSubActivity={this.handleSubActivity}
          activities={this.state.activities}
          taskList={this.state.taskList}
          taskName={this.state.taskName}
          handleTaskChange={this.handleTaskChange}
          subAccName={this.state.subAccName}
          taskCost={this.state.taskCost}
        />
      );
    }

    if (isEditContractor) {
      return (
        <EditLabour
          items={this.state.items}
          storeData={this.props.storeData}
          activity={this.state.activity}
          unit={this.state.unit}
          handleChange={this.handleChange}
          labourData={this.state.labourData}
          updateLabour={this.updateLabour}
          handleActivity={this.handleActivity}
          handleContractorChange={this.handleContractorChange}
          handleAddItem={this.handleAddItem}
          handleDeleteItem={this.handleDeleteItem}
          handleItemChange={this.handleItemChange}
          contractorIndex={this.state.contractorIndex}
          contractorName={this.state.contractorName}
          contractorAmount={this.state.contractorAmount}
          CancelEditContractor={this.CancelEditContractor}
          activityIndex={this.state.activityIndex}
          handleSubActivity={this.handleSubActivity}
          activities={this.state.activities}
          taskList={this.state.taskList}
          taskName={this.state.taskName}
          handleTaskChange={this.handleTaskChange}
          subAccName={this.state.subAccName}
          taskCost={this.state.taskCost}
        //  activityIndex={this.state.activityIndex}
        />
      );
    }
    return (
      <div>
        {this.props.notifications && (
          <Notifications notifications={this.props.notifications} />
        )}
        {this.state.isEditable && (
          <section className={Style.button__display}>
            <Button
              onClick={this.toggleCreateNewContractor}
              startIcon={<Add />}
              size="large"
            >
              Add Contractor
            </Button>
          </section>
        )}
        {/* <Grid container justify="center">
          <Grid item>
            <MUIDataTable
              title={`Contractors`}
              data={this.state.labourData}
              columns={this.columns}
              options={{
                selectableRows: "none",
                responsive: "simple",
                pagination: false,
                download: false,
                viewColumns: false,
                filter: true,
                print: false,
              }}
            />
          </Grid>
        </Grid> */}
           <div className="container" style={{ marginTop: "20px" }}>
          {this.state.sortedContractor != undefined &&
          Object.keys(this.state.sortedContractor).length !== 0
            ? Object.keys(this.state.sortedContractor).map(
                (contractor, index) => {
                  return (
                    <Accordion
                      expanded={this.state.expanded === `panel${index + 1}`}
                      onChange={this.handleExpandChange(`panel${index + 1}`)}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <Typography variant="h6">
                        {contractor}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ display: "inline" }}>
                        {/* <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justify="center"
                        style={{
                          marginBottom: 20,
                          padding: 20,
                        }}
                      >
                        <Grid item lg={6}></Grid>
                      </Grid> */}
                      <>
                        {
                        Object.keys(
                          this.state.sortedContractor[contractor]
                        ).map((acc, acc_index) => {
                          let curr_acc =
                            this.state.sortedContractor[contractor][acc];
                          return (
                            <>
                            <div
                                style={{
                                  marginTop:"20px",
                                  marginBottom:"20px",
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  flexWrap: "wrap",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    flexWrap: "wrap",
                                    flexGrow: 1,
                                  }}
                                >
                                  {/* <Typography variant="h6">
                                   contracto name
                                  </Typography> */}
                                   <Typography
                                    style={{ marginRight: "10px" }}
                                    variant="h6"
                                  >
                                    Activity: {this.state.contractorData[parseInt(curr_acc.index)].activity}
                                  </Typography>
                                  <Typography
                                    style={{ marginRight: "20px" }}
                                    color="textSecondary"
                                    variant="h6"
                                  >
                                    Amount: {curr_acc.contractorAmount}
                                  </Typography>
                                </div>

                                <div>
                                  <Grid item>
                                    <IconButton
                                      aria-label="settings"
                                      onClick={() => {
                                        this.toggleEditContractor(curr_acc.index);
                                      }}
                                      style={{
                                        backgroundColor: "#2E86AB",
                                        color: "#fff",
                                        padding: 4,
                                        marginRight: 4,
                                      }}
                                    >
                                      <Edit />
                                    </IconButton>
                                    <IconButton
                                      aria-label="settings"
                                      onClick={() => {
                                        this.deleteContractor(curr_acc.index);
                                      }}
                                      style={{
                                        backgroundColor: "red",
                                        color: "#fff",
                                        padding: 4,
                                      }}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </Grid>
                                </div>
                              </div>{" "}
                             
                              <MUIDataTable
                                title={`Items`}
                                data={curr_acc.items}
                                columns={this.columns}
                                options={{
                                  selectableRows: "none",
                                  responsive: "simple",
                                  pagination: false,
                                  download: false,
                                  viewColumns: false,
                                  filter: true,
                                  print: false,
                                }}
                              />
                            </>
                          );
                        })}
                        </>
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              )
            : ""}
        </div>
      </div>
    );
  }
}

Display.propTypes = {
  labour: PropTypes.object.isRequired,
};

export default Display;
