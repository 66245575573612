import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  Button,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
const useStyles = makeStyles({
  box: {
    maxHeight: "80vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "primary",
      outline: "1px solid slategrey",
    },
  },
  imageFull: {
    width: "100%",
  },
});
export default function DialogBox(props) {
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <Dialog
        open={props.isModalOpen}
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Panaroma Image</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img
              className={classes.imageFull}
              src={props.modelImageUrl}
              //   alt="ss"
              loading="lazy"
            />
          </DialogContentText>
          <DialogActions style={{ marginBottom: "1.5em", marginRight: "1em" }}>
            <Button
              onClick={() => {
                props.handleShowDialog("");
              }}
              color="secondary"
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
