import React from "react";

import { useTheme } from "@material-ui/core/styles";
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    Select,
    MenuItem,
    Snackbar,
    Slide,
    FormControl,
    InputLabel
} from "@material-ui/core";

import FileBase64 from "react-file-base64";
export default function AddRemark(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isEdit = props.isEdit;
    return (
      <div>
        <Dialog open={props.open} fullScreen={fullScreen}>
          <DialogTitle>{!isEdit ? "Add Remark" : "Update Todo"}</DialogTitle>
          <DialogContent>
            <DialogContentText>Provide a new Details</DialogContentText>
            {!isEdit ? (
              <div>
                <TextField
                  multiline
                  margin="dense"
                  id="remarkName"
                  name="remarkDescription"
                  label="Remark Description"
                  type="text"
                  variant="standard"
                  value={props.remarkDescription}
                  // error={props.alertSubActivity}
                  // helperText={props.alertSubActivityField}
                  onChange={props.handleRemarkChange}
                  fullWidth
                />
                <FileBase64
                  multiple={false}
                  className="btn"
                  value={props.remarkFile}
                  onDone={file => {
                    // files.forEach(ele => ele.base64);
                    props.handleFile(file.base64);
                  }}
                />
              </div>
            ) : (
              <div>
                <TextField
                  multiline
                  margin="dense"
                  id="taskTitle"
                  name="taskTitle"
                  label="Task Title"
                  type="text"
                  variant="standard"
                  value={props.taskTitle}
                  // error={props.alertSubActivity}
                  // helperText={props.alertSubActivityField}
                  onChange={props.handleUpdateChange}
                  fullWidth
                />
                <TextField
                  multiline
                  margin="dense"
                  id="taskDetail"
                  name="taskDetail"
                  label="Task Description"
                  type="text"
                  variant="standard"
                  value={props.taskDetail}
                  // error={props.alertSubActivity}
                  // helperText={props.alertSubActivityField}
                  onChange={props.handleUpdateChange}
                  fullWidth
                />

                <FormControl fullWidth>
                  <InputLabel id="activity" required>
                    Status
                  </InputLabel>
                  <Select
                    labelId="activity"
                    id="activity"
                    value={!props.completed?"Progress":"Complete"}
                    label="activity"
                    onChange={event => {
                      props.setStatus(event.target.value);
                    }}
                  >
                    {["Complete", "Progress"].map(item => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleShowDialog} color="secondary">
              Cancel
            </Button>
            {/* <Button onClick={props.submitTask} color="primary"> */}
            <Button
              onClick={!isEdit ? props.submitRemark : props.updateSingleTodo}
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}
