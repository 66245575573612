import React, { useState, useEffect, useCallback } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

import { Chart, registerables, ArcElement } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";

export default function BarchartData(props) {
  const [chartData, setChartData] = useState({
    labels: props.accNames,
    datasets: createDataset(props),
  });
  const [accNames, setAccNames] = useState(props.accNames);
  const [completedPer, setCompletedPer] = useState(0);

  // console.log('estimatedCompletion' + props.accNames)
  function createDataset(props) {
    const arr = [
      {
        label: props.percentageLabel,
        data: props.percentage,
        backgroundColor: ["#345BAE"],
        // barThickness: 60,
      },
    ];

    if (props.percentage1)
      arr.push({
        label: props.percentage1Label,
        data: props.percentage1,
        backgroundColor: ["#eea47fff"],
      });
    return arr;
  }
  useEffect(() => {
    Chart.register(...registerables);

    Chart.register(ArcElement);
    Chart.register(zoomPlugin);

    // const isAddVisible = roles.find(item => item == role) ? true : false;
  }, []);

  // console.log(this.state.completedPer);

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "10px",
      }}
    >
      <Bar
        data={chartData}
        options={{
          maintainAspectRatio: true,
          scales: {
            x: {
              min: 0,
              max: 2,
            },
          },
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: "x",
                threshold: 1,
              },
            },
          },
        }}
        height={120}
        width={100}
      />
    </div>
  );
}
