import React, { useEffect, useRef, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import {
  getBarChartData,
  saveBarChart,
} from "../../Redux/BarCharts/barChartAction";
import Loading from "../../Components/Loading";
import Item from "./Item";
import { Save } from "@material-ui/icons";
import GetAppIcon from "@material-ui/icons/GetApp";
import Notifications from "react-notification-system-redux";
import editRoles from "./planningEditRoles";

const BarChart = props => {
  const [data, setData] = useState([]);
  const [csvFile, setCsvFile] = useState();
  const [csvArray, setCsvArray] = useState([]);
  const [sub, setsubmit] = useState(false);
  const [isEditable, setEditable] = useState(() => {
    const role = global.config.secureStorage.getItem("role");
    return editRoles.find(item => item === role) ? true : false;
  });

  useEffect(() => {
    async function fetchData() {
      await props.getBarChartData();
    }
    fetchData();
  }, []); //eslint-disable-line

  useEffect(() => {
    let arr = props.defaultActivityNames;
    for (var i = 0; i < props.defaultActivityNames.length; i++) {
      if (arr[i][3] === -1 || arr[i][3] === "") {
        arr[i][3] = 0;
      }
      if (arr[i][4] === -1 || arr[i][4] === "") {
        arr[i][4] = 0;
      }

      arr[i] = [arr[i][0], arr[i][1], arr[i][2], arr[i][3], arr[i][4]];
    }
    setData([...arr]);
  }, [props.defaultActivityNames]);

  const handleStartDate = (index, date) => {
    if (data.length === 0) {
      setData(props.defaultActivityNames);
    }
    setData(prev => {
      prev[index][1] = date;
      return [...prev];
    });
  };

  const handleEndDate = (index, date) => {
    if (data.length === 0) {
      setData(props.defaultActivityNames);
    }
    setData(prev => {
      prev[index][2] = date;
      return [...prev];
    });
  };

  // const handleStartCost = (index, cost) => {
  //   if (data.length === 0) {
  //     setData(props.defaultActivityNames);
  //   }
  //   setData(prev => {
  //     prev[index][3] = cost;
  //     return [...prev];
  //   });
  // };

  // const handleEndCost = (index, cost) => {
  //   if (data.length === 0) {
  //     setData(props.defaultActivityNames);
  //   }
  //   setData(prev => {
  //     prev[index][4] = cost;
  //     return [...prev];
  //   });
  // };

  const processCSV = (str, delim = ",") => {
    console.log("string");
    console.log(str);
    const headers = str.slice(0, str.indexOf("\n")).trim().split(delim);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");
    const newArray = rows.map(row => {
      const values = row.trim().split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];

        return obj;
      }, {});
      return eachObject;
    });
    setCsvArray(newArray);

    for (var i = 0; i < props.defaultActivityNames.length; i++) {
      if (props.defaultActivityNames[i][0] === newArray[i].Activity) {
        // handleStartCost(i, newArray[i]["Starting Cost(in Rs)"]);
        // handleEndCost(i, newArray[i]["Ending Cost(in Rs)"]);
        if (newArray[i]["Start Date"] !== "") {
          var newdate = new Date(newArray[i]["Start Date"]);
          var date2 = newdate.toISOString();
          handleStartDate(i, date2);
        }
        if (newArray[i]["End Date"] !== "") {
          var newdate2 = new Date(newArray[i]["End Date"]);
          var date3 = newdate2.toISOString();
          handleEndDate(i, date3);
        }
      }
    }
  };

  const submit = () => {
    setsubmit(true);
    const file = csvFile;
    const reader = new FileReader();
    reader.onload = function (e) {
      const text = e.target.result;
      processCSV(text);
    };
    reader.readAsText(file);
  };

  if (props.loading) {
    return <Loading loading={true}></Loading>;
  }

  if (props.isError) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "80vh",
        }}
      >
        <h2 style={{ textAlign: "center" }}>Something Went Wrong!!</h2>
      </div>
    );
  }

  return (
    <div style={{ padding: 20 }}>
      <Notifications notifications={props.notifications} />
      <Grid container alignItems="center" justify="space-between">
        {/* <Grid item>
          <CardHeader title="Bar Chart" />
        </Grid> */}
        {isEditable && (
          <div
            style={{
              width: "100%",
              // border: "2px solid red",
              textAlign: "end",
              marginBottom: "20px",
            }}
          >
            <Grid item>
              <Button
                style={{ marginRight: "12px" }}
                onClick={async () => {
                  if (data.length === 0) {
                    console.log(data);
                    await props.saveBarChart(props.defaultActivityNames);
                    return;
                  }
                  props.saveBarChart(data);
                }}
                endIcon={<Save />}
              >
                Submit
              </Button>
              <Button>
                <CSVLink
                  data={[
                    [
                      "Activity",
                      "Start Date",
                      "End Date",
                      // "Starting Cost(in Rs)",
                      // "Ending Cost(in Rs)",
                    ],
                    ...data,
                  ]}
                  style={{ color: "white", textDecoration: "none" }}
                  onClick={() => {
                    console.log(data);
                  }}
                >
                  Export
                </CSVLink>
                <GetAppIcon />
              </Button>
              <form id="csv-form">
                <div style={{ marginTop: "12px" }}>
                  <input
                    type="file"
                    accept=".csv"
                    id="csvFile"
                    onChange={e => {
                      setCsvFile(e.target.files[0]);
                    }}
                  ></input>
                </div>
                <Button
                  onClick={e => {
                    e.preventDefault();
                    if (csvFile) {
                      submit();
                      alert("file uploaded successfully");
                    } else {
                      alert("please upload file");
                    }
                  }}
                >
                  UPLOAD
                </Button>
              </form>
            </Grid>
          </div>
        )}
      </Grid>
      {data.map((item, index) => {
        console.log(item);
        return (
          <Item
            isEditable={isEditable}
            key={index}
            name={item[0]}
            startdate={item[1]}
            enddate={item[2]}
            startcost={item[3]}
            endcost={item[4]}
            // handleStartCost={handleStartCost}
            // handleEndCost={handleEndCost}
            handleStartDate={handleStartDate}
            index={index}
            handleEndDate={handleEndDate}
            submit={sub}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    defaultActivityNames: state.barChartReducer.defaultActivityNames,
    loading: state.barChartReducer.loading,
    isError: state.barChartReducer.isError,
    notifications: state.notifications,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBarChartData: () => dispatch(getBarChartData()),
    saveBarChart: data => dispatch(saveBarChart(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarChart);
